import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Welcome" />
	<h2>Welcome</h2>
	<div className="main--centerwrapper">
		<StaticImage
		src="../images/photos/pressRes/rebecca1.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>I'm Jesse Rivest</h3>
	<p>
		Thanks for stopping by and taking a break from social media.
		If you're new here, get started at either
		my <Link to="/events/">shows</Link> or <Link to="/music/">music</Link> pages.<br />
		Also consider my <a href="//bit.ly/email-list-JR">mailing list</a> (frequency: 3 to 4 months) or my new, payment-optional <a href="//patreon.com/jesserivest/">Patreon</a> (frequency: weekly or biweekly)!
	</p>

	<div className="main--centerwrapper p">
		<StaticImage
		src="../images/catalog artwork/JRIVEST17 distant december front.png"
		alt="Jesse Rivest - Distant December - cover art"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>Distant December</h3>
	<p>
		November and December 2023 had me in Canada playing a handful of shows and promoting my
		wintry album, <Link to="/music/17/"><span className="italic">Distant December</span></Link>.
		November started off with the first single, <Link to="/music/16/"><span className="italic">Look at What You've Got</span></Link>, and then
		the whole album dropped in December.
	</p>
	<p className="card">
		<span className="card">
			I have printed/pressed copies of the album—plus tee shirts via fast POD—all available at the <Link to="/merch/">Merch page</Link>.
		</span>
	</p>
  </Layout>
);

export default IndexPage;
